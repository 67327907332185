.huauNA{
    padding-bottom:45px;
    background:#032a51;
    background-repeat:no-repeat;
    background-size:cover;
    position:relative;
}

.huauNA .section__particle{
    position:absolute;
}

.huauNA .section__particle.one{
    left:0;
    top:20%;width:270px;
}

.huauNA ul{
    padding:0;
    list-style:none;
    margin:0;
}

.huauNA ul li .anchor{
    color:#ffffff;
}
.huauNA p{font-size:15px;
    line-height:38px;
    color:#ffffff;
}

.huauNA .anchor{
    -webkit-transition:450ms all;
    transition:450ms all;
    color:#ffffff;
}
.huauNA .anchor:hover{
    color:#44d62c;
}

.huauNA .footer-logo{
    margin-bottom:15px;
    display:block;
}
.huauNA .footer-widgets.first{
    margin-top:-10px;
}

.huauNA .footer-widgets .widget-title{
    color:#ffffff;
    font-size:24px;
    margin-bottom:35px;
}

.huauNA .footer-widgets .info{
    font-size:18px;
    line-height:38px;
    margin-bottom:32px;
}

.huauNA .footer-widgets .info li{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;align-items:center;
}

.huauNA .footer-widgets .info li svg{
    margin-right:10px;color:#ffffff;
}

.huauNA .footer-widgets .social li{
    display:inline-block;
}

.huauNA .footer-widgets .social li .anchor{
    display:block;
    height:60px;
    width:60px;
    line-height:60px;
    border-radius:8px;
    border:1px solid #ffffff;
    text-align:center;
    -webkit-transition:450ms all;
    transition:450ms all;
}

.huauNA .footer-widgets .social li .anchor:hover{
    color:#ffffff;
    background:rgb(251,123,129);
    background:-moz-linear-gradient( left, rgba(251,123,129,1) 0%, rgba(254,191,179,1) 100% );
    background:-webkit-linear-gradient( left, rgba(251,123,129,1) 0%, rgba(254,191,179,1) 100% );
    background:linear-gradient( to right, rgba(251,123,129,1) 0%, rgba(254,191,179,1) 100% );
    -webkit-filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb7b81',endColorstr='#febfb3',GradientType=1 );
    filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb7b81',endColorstr='#febfb3',GradientType=1 );
    border-color:#febfb3;
}

.huauNA .footer-widgets .social li + li{
    margin-left:30px;
}

.huauNA .footer-widgets .widget-catagory li .anchor{
    font-size:18px;line-height:22px;
}

.huauNA .footer-widgets .widget-catagory li + li{
    margin-top:15px;
}

.huauNA .footer-bottom{
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding-top:40px;
    margin-top:100px;
}

.huauNA .footer-bottom .footer-menu{
    -webkit-order:2;
    -ms-flex-order:2;
    order:2;
}

.huauNA .footer-bottom .footer-menu li{
    display:inline-block;
    font-size:15px;
}

.huauNA .footer-bottom .footer-menu li + li{
    margin-left:40px;
}

.huauNA .footer-bottom p{
    -webkit-order:1;
    -ms-flex-order:1;
    order:1;
    margin:0;
}

@media only screen and (max-width:1600px){
    .huauNA .section__particle.one{
        display:none;
    }
}

@media only screen and (max-width:1280px){
    .huauNA .footer-widgets .social li + li{
        margin-left:15px;
    }
}

@media only screen and (max-width:912px){
    .huauNA{
        background-position:-200px;
    }
    
    .huauNA .footer-widgets{
        margin-bottom:70px;
    }
    
    .huauNA .footer-bottom{
        display:block;
        margin-top:30px;
    }
    
    .huauNA .footer-bottom .footer-menu{
        margin-bottom:40px;
    }
    .huauNA .footer-bottom .footer-menu,.huauNA .footer-bottom .copyright-text{
        text-align:center;
    }
}

@media only screen and (max-width:568px){
    .huauNA{
        background-position:10%;
        text-align:center;
    }

    .huauNA .footer-widgets .info li{
        -webkit-box-pack:center;
        -webkit-justify-content:center;
        -ms-flex-pack:center;
        justify-content:center;
    }

}

@media only screen and (max-width:375px){
    .huauNA .footer-bottom .footer-menu li{
        display:block;
    }

    .huauNA .footer-bottom .footer-menu li + li{
        margin-left:0;
        margin-top:30px;
    }
}
    