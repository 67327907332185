.navbar{
    background: transparent;
    position:fixed;
    width:100%;
    z-index:9999;
    top:0;
    -webkit-transition:all 0.3s ease 0s;
    transition:all 0.3s ease 0s;
    height:110px;
}

.navbar.active{
    background: linear-gradient(90deg, rgba(36,37,38), rgba(0,12,164) 150%);
}

.navbar .navbar-wrap{
    width:100%;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -webkit-justify-content:space-between;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
}

.navbar .mobile-menu-icon{
    display:none;
    color:#ffffff;
    font-size:24px;
}

.navbar .dropdown{
    background:#000ca4;
    visibility:hidden;
    opacity:0;
    min-width:200px;
    border:1px solid #f5f5f5;
    position:absolute;
    margin-top:1rem;
    left:20px;
    padding:15px 0px;
    display:none;
    box-shadow:0px 30px 70px 0px rgba(137,139,142,0.15);
    -webkit-transform:translateY(20px);
    -ms-transform:translateY(20px);
    -webkit-transform:translateY(20px);
    -ms-transform:translateY(20px);
    transform:translateY(20px);
    -webkit-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
}

.navbar ul li:hover > ul,
.navbar ul li ul:hover{
    opacity:1;
    visibility:visible;
    -webkit-transform:translateY(0px);
    -ms-transform:translateY(0px);
    -webkit-transform:translateY(0px);
    -ms-transform:translateY(0px);
    transform:translateY(0px);
    display:block;
}

.navbar nav ul{
    margin:0;
    padding:0;
    list-style:none;
    display:-webkit-box;
    display:-webkit-flex;
    display:-ms-flexbox;
    display:flex;
}

.navbar nav li{
    position:relative;
    padding:40px 0px 30px 40px;
}

.navbar nav li .dropdown li{
    padding:0;
}

.navbar nav li .dropdown li a{
    color:#ffffff;
    display:block;
    padding:5px 15px;
}

.navbar nav li .dropdown li a:hover{
    color:#44d62c !important;
}

.navbar nav li .dropdown li a:before{
    content:none;
}

.navbar nav li a,
.navbar nav li .nav-active {
    position:relative;
    color:#ffffff;
    font-size:15px;
    -webkit-transition:450ms all;
    transition:450ms all;
    padding-bottom:5px;
    cursor:pointer;
    white-space: nowrap;
    padding-top:10px;
}

.navbar nav li.btn{
    padding:30px 0px 40px 40px;
    cursor:pointer;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family:inherit;
    -webkit-text-decoration:none;
    text-decoration:none;
    border:0;
}

.navbar nav li.btnx{
    position:relative;
    cursor:pointer;
    margin-left:0;
    padding-left: 0;
    border-radius: 0 5px 5px 0;
    -webkit-transition:450ms all;
    transition:450ms all;
    z-index:999;
    font-size:18px;
}

.navbar nav li.btn a,
.navbar nav li.btn .nav-active {
    padding:8px;
    border-radius:5px;
    -webkit-transition:450ms all;
    transition:450ms all;
    position:relative;
    z-index:999;
    background:#ffffff;
    color:#44d62c;
    border:2px solid #fff;
}

.navbar nav li.btn a:hover {
    background:#44d62c;
    color:#333333;
}

.navbar nav li a:before,
.navbar nav li .nav-active:before{
    content:'';
    position:absolute;
    left:0;
    bottom:0;
    width:20px;
    height:1px;
    opacity:0;
    background:#44d62c;
    -webkit-transition:450ms all;
    transition:450ms all;
}

.navbar nav li a:hover,
.navbar nav li .nav-active:hover{
    color:#44d62c;
}

.navbar nav li a:hover:before,
.navbar nav li .nav-active:hover:before{
    opacity:1;
}

.navbar .logo{
    margin-top:8px;
    -webkit-transition:all 0.8s cubic-bezier(0.3,0.8,0.2,1) 0s;
    transition:all 0.8s cubic-bezier(0.3,0.8,0.2,1) 0s;
}

.navbar .logo img{
    width:135px;
}

.navbar.scrolled{
    box-shadow:rgba(0,0,0,0.05) 0px 10px 30px;
    background:#000ca4;
}

.navbar.scrolled .logo{
    width:100px;
}

.navbar.scrolled .nav li{
    padding:28px 0px 28px 40px;
}

.navbar.scrolled .nav li a{
    color:#ffffff;
}

.navbar.scrolled .nav li a:hover{
    color:#44d62c;
}

.navbar.scrolled .nav li .dropdown li{
    padding:0;
}

.buttonDiv .banner-btn{
    cursor:pointer;
    min-width:70px;
    font-size:16px;
    border:0;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family:inherit;
    font-size:16px;
    -webkit-text-decoration:none;
    text-decoration:none;
    text-transform:capitalize;
    padding:14px;
    border-radius:3px;
    -webkit-transition:450ms all;
    transition:450ms all;
}

.buttonDiv .banner-btn.two{
    /* background:#ffffff; */
    color:#44d62c;
    margin-left:10px;
}

.buttonDiv .banner-btn.two:hover{
    background:#44d62c;
    color:#333333;
}

.buttonDiv .banner-btn:hover,
.buttonDiv .banner-btn:focus{
    outline:none;
    background:#73e161;
}

#style15::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

#style15::-webkit-scrollbar{
	width: 10px;
	background-color: #F5F5F5;
}

#style15::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #FFF;
	background-image: -webkit-gradient(linear,
	    40% 0%,
	    75% 84%,
	    from(#4D9C41),
	    to(#19911D),
	    color-stop(.6,#54DE5D))
}

@media only screen and (max-width:912px){
    .navbar{
        height:auto;
        min-height:50px;
        padding:15px 20px;
        background:#032a51;
    }

    .navbar.scrolled{
        padding:8px 20px;
    }

    .navbar.scrolled .nav .collapsed li{
        padding:0;
    }

    .navbar.scrolled .nav .collapsed li a{
        padding:8px 10px;
        display:block;
    }

    .navbar.scrolled .nav .collapsed li + li{
        padding-left:0;
    }

    .navbar .has-dropdown a{
        position:relative;
    }

    .navbar .has-dropdown a:before{
        content:'';
    }

    .navbar .dropdown{
        position:relative;
        left:0;
        margin-top:0;
        box-shadow:none;
    }

    .navbar .navbar-wrap{
        display:block;
        position:relative;
    }

    .navbar .logo{
        width:100%;
        display:block;
        margin:7px 0 0 0;
    }

    .navbar .logo img{
        width:110px;
    }

    .navbar .mobile-menu-icon{
        display:inline-block;
        position:absolute;
        top:8px;
        right:3px;
        cursor:pointer;
    }

    .navbar .nav{
       width:100%;
    }

    .navbar .nav .collapsed{
        width:100%;
        display:-webkit-box;
        display:-webkit-flex;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-pack:justify;
        -webkit-justify-content:space-between;
        -ms-flex-pack:justify;
        justify-content:space-between;
        -webkit-flex-wrap:wrap;
        -ms-flex-wrap:wrap;
        flex-wrap:wrap;
        -webkit-transition:450ms all ease-in-out;
        transition:450ms all ease-in-out;
        overflow:hidden;
        max-height:0;

    }

    .navbar .nav .collapsed.is-expanded{
        /* padding-top:15px; */
        max-height:250px;
        background:transparent;
        overflow-x:hidden;
    }

    .navbar .nav .collapsed li{
        padding:0;
        margin:0px 0px;
        width:100%;
    }

    .navbar .nav .collapsed li .dropdown li a{
        color:#ffffff;
    }

    .navbar .nav .collapsed li a{
        padding:8px 10px;
        display:block;
        color:#ffffff;
    }

    .navbar .nav .collapsed li.btn a{
        padding:8px 10px;
        display:block;
        color:#44d62c;
    }

    .navbar .nav .collapsed li a:hover{
        color:#44d62c;
    }

    .navbar .nav .collapsed li.btn a:hover{
        padding:8px 10px;
        display:block;
        color:#000;
    }

    .navbar .nav .collapsed li a:before{
        content:none;
    }
}