.wrap-input100{
    width:100%;
}

.input100{
    line-height:1.2;
    font-size:16px;
    display:block;
    width:100%;
    height:40px;
    padding:0 20px;
    margin-bottom:10px;
}

.login100-form-btn{
    width:100%;
}

.login100-form-btn:hover,
.login100-form-btn:focus{
    outline:none;
    background:#73e161;
}

.login100-form-btn .icon-left{
    margin-right:8px;
}

.login100-form-btn .icon-right{
    margin-left:8px;
}