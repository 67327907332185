.clock {
    // width: 120px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // border: solid 1px #ccc;
    border-radius: 5px;
    margin: 5px;

    // background: transparent;
    .digital-clock {
        padding: 10px;
        color:#000;
    }

    .analog-clock {
        margin-top: 10px;
        width: 150px;
        height: 150px;
        border: solid 3px #126e04;
        border-radius: 50%;
        position: relative;
        background: #fff;
        .dial {
            position: absolute;
            left: 50%;
            width: 75px;
            height: 75px;
            transform-origin: bottom left;

        &.seconds {
            border-left:solid 1px rgb(86, 87, 86);
            width: 68px;
            height: 68px;
            top: 5px;
        }

        &.minutes {
            border-left:solid 3px #cc0000;
            width: 68px;
            height: 68px;
            top: 5px;
        }

        &.hours {
            width: 50px;
            height: 50px;
            top: 23px;
            border-left:solid 3px #000;
        }
        }
    }
}