@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
.profilecard {
  font-size: 14px;
  color: #404040;
  font-family: Montserrat, sans-serif;
  /*background-image: linear-gradient(to bottom right, #ff9eaa 0% 65%, #e860ff 95% 100%);*/
  background-position: center;
  background-attachment: fixed;
  margin: 0;
  padding: 1rem 0;
  display: grid;
  place-items: center;
  box-sizing: border-box;
}
.card {
  background-color: #fff;
  max-width: 360px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0px 1rem 1.5rem rgba(0,0,0,0.5);
}
.card .banner {
  background-image: url("https://images.unsplash.com/photo-1677177751361-0416255ff002?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 6rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
}
.card .banner img {
  background-color: #fff;
  width: 8rem;
  height: 8rem;
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.3);
  border-radius: 50%;
  transform: translateY(50%);
  transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.card .banner img:hover {
  transform: translateY(50%) scale(1.4);
}
.card .menu {
  width: 100%;
  height: 5.5rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}
.card .menu .opener {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  border-radius: 50%;
  transition: background-color 100ms ease-in-out;
}
.card .menu .opener:hover {
  background-color: #f2f2f2;
}
.card .menu .opener span {
  background-color: #404040;
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  top: 0;
  left: calc(50% - 0.2rem);
  border-radius: 50%;
}
.card .menu .opener span:nth-child(1) {
  top: 0.45rem;
}
.card .menu .opener span:nth-child(2) {
  top: 1.05rem;
}
.card .menu .opener span:nth-child(3) {
  top: 1.65rem;
}
.card h2.name {
  text-align: center;
  padding: 0 2rem 0.5rem;
  margin: 0;
}
.card .title {
  color: #a0a0a0;
  font-size: 0.85rem;
  font-weight: bold;
  text-align: center;
  padding: 0 5px 0 5px;
}
.card .mobile {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 5px 0 5px;
}
.card .email {
  color: #a0a0a0;
  font-size: 0.85rem;
  text-align: center;
  padding: 0 5px 20px 5px;
}

.card .actions {
  padding: 0 2rem 1.2rem;
  display: flex;
  flex-direction: column;
  order: 99;
}

.card .actions .follow-info {
  padding: 0 0 1rem;
  display: flex;
}
.card .actions .follow-info h2 {
  text-align: center;
  width: 50%;
  margin: 0;
  box-sizing: border-box;
}
/*
.card .actions .follow-info button {
  text-align: center;
  width: 50%;
  margin: 0;  
}
*/

.card .actions .follow-info button {
  color: #000;
  font: inherit;
  font-weight: normal;
  background-color: #44d62c;
  width: 50%;
  border: none;
  padding: 1rem;
  outline: none;
  box-sizing: border-box;
  border-radius: 0.5rem/10%;
  transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.card .actions .follow-info a button {
  color: #000;
  font: inherit;
  font-weight: normal;
  background-color: #44d62c;
  width: 100%;
  border: none;
  padding: 1rem;
  outline: none;
  box-sizing: border-box;
  border-radius: 0.5rem/10%;
  transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.card .actions .follow-info h2 a {
  text-decoration: none;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  transition: background-color 100ms ease-in-out;
}
.card .actions .follow-info h2 a span {
  color: #1c9eff;
  font-weight: bold;
  transform-origin: bottom;
  transform: scaleY(1.3);
  transition: color 100ms ease-in-out;
}
.card .actions .follow-info h2 a small {
  color: #8f0303;
  font-size: 0.85rem;
  font-weight: normal;
}
.card .actions .follow-info h2 a:hover {
  background-color: #f2f2f2;
}
.card .actions .follow-info h2 a:hover span {
  color: #007ad6;
}
.card .actions .follow-btn button {
  color: #000;
  font: inherit;
  font-weight: normal;
  background-color: #44d62c;
  width: 100%;
  border: none;
  padding: 1rem;
  outline: none;
  box-sizing: border-box;
  border-radius: 1rem/30%;
  transition: background-color 100ms ease-in-out, transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.card .actions .follow-btn button:hover {
  background-color: #efb10a;
  transform: scale(1.1);
}
.card .actions .follow-btn button:active {
  background-color: #e8a200;
  transform: scale(1);
}
.card .desc {
  text-align: center;
  padding: 0 2rem 2.5rem;
  order: 100;
}

.card .inputform {
  text-align: left;
  padding: 0 2rem 2.5rem;
  order: 100;
}