.logout{
    float:right;
    margin:10px 0px;
}
.textbutton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
}
.registrationNote{
    font-size: 15px;
    line-height: 1.4em;
    margin:20px 0 10px 0;
}

.registrationNote span{
    font-weight: bold;
    color:#44d62c;
}

/* Sanny */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
    --main-color: linear-gradient(90deg, rgba(36,37,38), rgba(68,214,44) 150%);
    --color-dark: #1D2231;
    --text-grey: #8390A2;
}

* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style-type: none;
    box-sizing: border-box;
}

.light-shadow {
    box-shadow: 3px 3px 3px rgba(0,.6,0,.6);
}

.moderate-shadow {
    box-shadow: 5px 5px 5px rgba(0,.6,0,.8);
}

#sidebar-toggle {
    display: none;
}

.sidebar {
    height: 100%;
    width: 240px;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background: var(--main-color);
    color: #fff;
    overflow-y: auto;
    transition: width 500ms;
}
.sidebar ul li {
    margin-bottom: 14px;
    white-space: nowrap;
}

.sidebar ul li.logoutbtn {
    margin-top: 35px;
}

.sidebar ul li.marketplace {
    margin-top: 30px;
    font-size: 1.1em;
}

.sidebar ul li.marketplace a {
    color:#f3e69d;
}

.sidebar ul li.marketplace a:hover {
    color:#bda418;
}

.sidebar a:hover, .sidebar-header label:hover {
    color:#69da55;
    cursor:pointer;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0rem 1rem;
}

.sidebar-menu {
    padding: 1rem;
    text-shadow: 3px 3px 3px rgba(0,.6,0,.9);
}

.sidebar-menu ul li i {
    font-size: 1.4em;
    width:32px;
    text-align: center;
}

.sidebar li {
    margin-bottom: 1.5rem;
}

.sidebar a {
    color: #fff;
    /* font-size: .8rem; */
}

/* .sidebar a span:last-child {
    padding-left: .6rem;
} */

#sidebar-toggle:checked ~ .sidebar {
    width: 60px;
}

#sidebar-toggle:checked ~ .sidebar .sidebar-header h3 span,
#sidebar-toggle:checked ~ .sidebar li span:last-child, .sidebar li button.btn {
    display: none; /* hide text in menus */
}

#sidebar-toggle:checked ~ .sidebar .sidebar-header,
#sidebar-toggle:checked ~ .sidebar li {
    display: flex;
    justify-content: center;
}

#sidebar-toggle:checked ~ .main-content {
    margin-left: 60px;
}

#sidebar-toggle:checked ~ .main-content .headerbaradmin {
    left: 60px;
    width: calc(100% - 60px);
}

.sidebar li button.btn{
    cursor:pointer;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family:inherit;
    -webkit-text-decoration:none;
    text-decoration:none;
    border:0;
    
}

.sidebar li button.btn a,
.sidebar li button.btn {
    padding:8px;
    border-radius:5px;
    -webkit-transition:450ms all;
    transition:450ms all;
    position:relative;
    z-index:999;
    background:#ffffff;
    color:#44d62c;
    border:2px solid #fff;
}

.sidebar li button.btn:hover{
    background:#44d62c;
    color:#333333;
    
}
/* Sanny */
.main-content {
    position: relative;
    margin-left: 240px;
    transition: margin-left 500ms;
}

.headerbaradmin {
    position: fixed;
    left: 240px;
    top: 0;
    z-index: 9998;
    width: calc(100% - 240px);
    background: #fff;
    color:#18191A;
    height: 60px;
    padding: 0rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    transition: left 500ms;
    text-align: left;
}
.headerbaradmin .headerTeamDesignation {
    font-size:0.8em;
    text-align: left;
    color:#18750a;
    padding:0;
}

.headerbaradmin .headerTeamDesignation span i {
    font-size:2em;
}

ul.teamDesignationDropMenu li .btn, ul.teamDesignationDropMenu li div.btn a {
    width:90%;
    border-radius:5px;
    -webkit-transition:450ms all;
    transition:450ms all;
    position:relative;
    z-index:999;
    background:#ffffff;
    color:#44d62c;
    border:2px solid #fff;
    margin:auto;
}
ul.teamDesignationDropMenu li {
    padding:0;
}
ul.teamDesignationDropMenu li .btn:hover {
    background:#44d62c;
    color:#333333;
}

.adminsearch-wrapper {
    display: flex;
    align-items: center;
}

.adminsearch-wrapper input {
    border: 0;
    outline: 0;
    padding: 1rem;
    height: 38px;
}
.loggedUserInfoName {
    line-height: 1.2em;
}

.loggedUserInfoName h6 {
font-weight: 500;
font-size: .8em;
}

.loggedUserInfoDesc {
    display: flex;
    align-items: center;
    font-size: .8em;
    color:#44d62c;
}

.loggedUserInfoDesc span,
.loggedUserInfoDesc div {
    margin-left: 1.2rem;
}

.loggedUserInfoDesc span {
    overflow:hidden;
    color: #000;
    font-size: 0.8em;
}

.loggedUserInfoDesc div, .loggedUserInfoDesc img  {
    height: 38px;
    width: 38px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
}

main {
    margin-top: 60px;
    background: #f1f5f9;
    min-height: 85vh;
    padding: 1rem 3rem;
}

main .sub-title {
    padding-bottom: 20px;
    color:#000;
    font-size: 1.1em;
}

.dash-title {
    color: var(--color-dark);
    margin-bottom: 1rem;
}

.dash-cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
}

.dash-cards-two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
}

.dash-cards-three {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 1rem;
}

.dash-cards-full {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 1rem;
}

.dashcardsprototype {
    display: grid;
    /* grid-template-columns: repeat(1, 1fr); */
    margin-bottom: 10px; 
}

.dashcardsprototype .card-single {
    color: darkred;
    font-weight: normal;
    font-size: 0.9em;
    text-align: center;
    padding:10px;
    border:1px solid;
}

.contactSysInfo {
    padding:10px;
}

.announcefixed {
    display: grid;
    /* grid-template-columns: repeat(1, 1fr); */
    margin-bottom: 10px; 
}

.announcefixed .card-single {
    color: darkred;
    font-weight: normal;
    font-size: .8em;
    text-align: center;
    padding:10px;
    border:1px solid;
}

.card-single {
    background: #fff;
    border-radius: 7px;
    /* box-shadow: 2px 2px 2px rgba(0,0,0,0.2); */
    margin-bottom: 1rem;
    position: relative;
}

.card-body {
    padding: 1.3rem 1rem;
    display: flex;
    align-items: center;
    padding-bottom: 43px;
}

.card-body span {
    font-size: 1.5rem;
    color: #777;
    padding-right: 1.4rem;
}

.card-body h5 {
    color: var(--text-grey);
    font-size: 1rem;
    line-height: 1.3rem;
}

.card-body h4 {
    color: var(--color-dark);
    font-size: 1.1rem;
    margin-top: .2rem;
}
.card-body h6 {
    color: var(--color-dark);
    font-size: 0.8rem;
    font-weight: normal;
    margin-top: .1rem 0 0 0;
    line-height: 1.5em;
}

.card-footer {
    padding: .2rem 1rem;
    background: linear-gradient(90deg, rgba(36,37,38), rgba(68,214,44) 150%);
    position: absolute;
    bottom: 0;
    width:100%;
    min-height: 30px;
}

.card-footer a {
    color: var(--main-color);
    font-size: 0.8em;
}

.card-footer button {
    float: right;
    font-size:0.7em;
    padding:2px;
    margin-top:2px;
    margin-left:1px;
}

.card-footer ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin-left: -1px; */
}
.card-footer li {
    /* flex-grow: 1;
    flex-basis: auto; */
    /* margin: .25em 0;
    padding: 0 1em; */
    text-align: left;
    
    /* border-left: 1px solid #ccc;
    background-color: #fff; */
}

.recent {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.activity-grid {
    display: grid;
    grid-template-columns: 73% 25%;
    grid-column-gap: 1.5rem;
    min-height:60vh;
}

.activity-grid-full {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 1.5rem;
    min-height:60vh;
}

.activity-card,
.summary-card,
.quicklinks-card {
    background: #fff;
    border-radius: 7px;
    color:#000;
}

.activity-card h3 {
    color: var(--text-grey);
    margin: 1rem 1rem 0 1rem;
   
}

.activity-card small {
    color: #000;
    margin: 0 1rem 10px 1rem;
    font-size: .7em;
    
}

.activity-card table {
    width: 100%;
    border-collapse: collapse;
}

.activity-card thead {
    background: #efefef;
    text-align: left;
}

/* .activity-card thead tr th {
    color:grey;
} */

th, td {
    font-size: .9rem;
    padding: 1rem 1rem;
    color: var(--color-dark);
}

td {
    font-size: .8rem;
}

tbody tr:nth-child(even) {
    background: #f9fafc;
}

.visitorResidentNote {
    font-weight:500;
    color:red;
}

.visitorName {
    font-weight:500;
    font-size:1.2em;
}

.senderName {
    font-weight:500;
    font-size:1.2em;
}

.badge, .card-body .badge {
    padding: .2rem 1rem;
    border-radius: 8px 0 8px 0;
    font-weight: 500;
    font-size: .7rem;
    /* white-space: nowrap; */
    display:inline-block;
    margin-top:3px;
}

.badge.success, .card-body .badge.success {
    background: #DEF7EC;
    /* color: var(--main-color); */
    color:#000;
}

.badge.warning {
    background: #F0F6B2;
    color: rgb(136, 90, 4);
}

.badge.critical {
    background: red;
    color: #fff;
}

.card-body h6 .dateRegistered {
    color: #0c4702;
    font-weight: bold;
    font-size: 1em;
}

.card-body h6 .autoDeleteDate {
    color: red;
    font-weight: bold;
    font-size: 1em;
    padding:0;
}

.card-body h6 .autoDispatch, .card-body h6 .bookingAdminNotes {
    color: red;
    font-size: 1em;
    padding:0;
}

.td-team {
    display: flex;
    align-items: center;
}

.td-team div {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    margin-left: -15px;
    border: 3px solid #efefef;
    background-size: cover;
    background-repeat: no-repeat;
}

.summary-card {
    margin-bottom: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.summary-single {
    padding: .5rem 1rem;
    display: flex;
    align-items: center;
}

.summary-single span {
    font-size: 1.5rem;
    color: #777;
    padding-right: 1rem;
}

.summary-single h5 {
    color: var(--main-color);
    font-size: 1.1rem;
    margin-bottom: 0rem !important;
}

.summary-single small {
    font-weight: 700;
    color: var(--text-grey);
}

.quicklinks-flex {
    display: flex;
    align-items: center;
    margin-bottom: .3rem;
}

.quicklinks-card {
    padding: 1rem;
    color:#000;
    line-height: 1.2;
    margin-bottom:5px;
}

.quicklinks-header {
    /* padding: 1rem; */
    color:#000;
    line-height: 1.2;
    margin-bottom:5px;
}

.quicklinks-info small {
    font-size: .7em;
    color:#000;
}

.quicklinks-info small a, .activity-card small a {
    color:#000;
}

.quicklinks-info small a:hover, .activity-card small a:hover {
    color:#69da55;
}

.quicklinks-info h3{
    color: var(--text-grey);;
}

.quicklinks-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 3px solid #efefef;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url(img/3.jpeg); */
    margin-right: .7rem;
}
.team-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 3px solid #efefef;
    margin-right: .7rem;
    margin-top: .7rem;
    float: right;
}
.inset-img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 3px solid #efefef;
    float: right;
}

.inset-customerlogo, .inset-supplierlogo {
    /* height: 70px;
    width: 50px; */
    /* border-radius: 50%; */
    /* border: 3px solid #efefef; */
    float: right;
}

.fullDiv {
    width:100%;
}

.text-center {
    text-align: center;
}

.text-center button {
    background: var(--main-color);
    color: #fff;
    border: 1px solid var(--main-color);
    border-radius: 4px;
    padding: .4rem 1rem;
}

.table-responsive {
    overflow-x: auto;
}

.table-responsive table tbody tr td{
    vertical-align: top;
    line-height:1.6em;
}

.table-responsive table thead tr th{
    line-height:1.6em;
}

.table-responsive table tbody tr td.unreadMessage {
    border-left:4px solid #18750a;
}

.unreadMessageSender {
    color: #18750a;
    font-weight:700;
}

table.messagesTable tbody tr td,  table.messagesTable thead tr th {
    border-left:4px;
    border-right:4px;
}
div .messagelocation {
    float: right;
    font-size: .8em;
}

.footersys {
    margin:5px;
    padding:5px; 
    color:#000;
    font-size:0.8em;
    text-align: center;
}

li.li-inline {
    display:inline;
}


ul.headerTeamDesignation li {
    display:inline;
}

.input-group {
    display:flex;
    flex-direction:row;
    border:1px solid #797878;
    padding:2px;
    margin-bottom:10px;
    background:#fff;
}

.text-input-in-group {
    /* flex-grow:2; */
    width: 100%;
    border:none;
    background:#fff;
    padding-left: 10px;
    padding-right: 10px;
}
.text-input-in-group:focus {
    outline: none;
}

.button-in-group{
    cursor:pointer;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family:inherit;
    font-size:16px;
    -webkit-text-decoration:none;
    text-decoration:none;
    text-transform:capitalize;
    border:0;
    padding:14px;
    border-radius:3px;
    -webkit-transition:450ms all;
    transition:450ms all;
    position:relative;
    color:#000;
    background-color:#44d62c;
    z-index:999;
}

.button-in-group:hover,
.button-in-group:focus{
    outline:none;
    background:#73e161;
}

/* ul.headerTeamDesignation > li:first-child {
    font-weight: 500;
    font-size: 1.2em;
    color:#000;
    margin-right:5px;
} */

ul.headerTeamDesignation > li+li+li {
    font-size: 1.1em;
    color:#000;
    margin-left:10px;
}

.dropdown {
    /* float: right; */
    overflow: hidden;
    /* display:inline-block; */
    display:inline;
    cursor: pointer;
}
  
  .dropdown .dropbtn i {
      font-size: 1.2em;
      margin-left:10px;
  }
  .dropdown .dropbtn {
    /* font-size: 16px;   */
    border: none;
    outline: none;
    color: black;
    /* padding: 14px 16px; */
    background-color: inherit;
    font-family: inherit;
    /* margin: 0; */
  }
  
  /* .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  } */
  
  .teamDesignationDropMenu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 3px rgba(0, 0, 0, 0.5);
    z-index: 1;
    font-size:0.8em;
    text-align: center;
    padding: 20px 0 10px 0;
  }
  
  .teamDesignationDropMenu li {
    margin-bottom:5px;
  }

  .teamDesignationDropMenu li button, .teamDesignationDropMenu li span {
    text-align: center;
  }
  
  /* .teamDesignationDropMenu a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  } */
  
  /* .teamDesignationDropMenu a:hover {
    background-color: #ddd;
  } */
  
  .dropdown:hover .teamDesignationDropMenu {
    display: block;
  }
/* .Toastify__toast-container--sysmessage {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

@media only screen and (max-width: 1200px) {
    .sidebar {
        width: 60px;
        z-index: 9999;
    }

    .sidebar .sidebar-header h3 span,
    .sidebar li span:last-child {
        display: none;
    }

    .sidebar .sidebar-header,
    .sidebar li {
        display: flex;
        justify-content: center;
    }

    .main-content {
        margin-left: 60px;
    }

    .main-content .headerbaradmin {
        left: 60px;
        width: calc(100% - 60px);
    }
    
    #sidebar-toggle:checked ~ .sidebar {
        width: 240px;
    }

    #sidebar-toggle:checked ~ .sidebar .sidebar-header h3 span,
    #sidebar-toggle:checked ~ .sidebar li span:last-child {
        display: inline;
    }

    #sidebar-toggle:checked ~ .sidebar .sidebar-header {
        display: flex;
        justify-content: space-between;
    }
    
    #sidebar-toggle:checked ~ .sidebar li {
        display: block;
    }

    #sidebar-toggle:checked ~ .main-content {
        margin-left: 60px;
    }

    #sidebar-toggle:checked ~ .main-content .headerbaradmin {
        left: 60px;
    }
}

@media only screen and (max-width: 920px) {
    .dash-cards, .dash-cards-three {
        grid-template-columns: repeat(2,1fr);
    }

}

@media only screen and (max-width: 860px) {
    .dash-cards, .dash-cards-three {
        grid-template-columns: repeat(2,1fr);
    }
    
    .card-single {
        margin-bottom: 1rem;
    }
    
    .activity-grid {
        display: block;
    }
    
    .summary {
        margin-top: 1.5rem;
    }

    .contactSysInfo {
        margin-top:20px;
    }
}

@media only screen and (max-width: 600px) {
    .dash-cards, .dash-cards-two, .dash-cards-three {
        grid-template-columns: 100%;
    }
}

@media only screen and (max-width: 450px) {
    main {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}