:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  outline: none;
  border: none;
  cursor: pointer;
}
 
.btn--primary {
  background-color: var(--primary);
  color: #242424;
  border: 1px solid var(--primary);
}
 
.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
}
 
.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}
 
.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}
  
.btn--large:hover,
.btn--medium:hover {
  transition: all 0.3s ease-out;
  background: #fff;
  color: #242424;
  transition: 250ms;
}

.buttonMain{
    cursor:pointer;
    display:-webkit-inline-box;
    display:-webkit-inline-flex;
    display:-ms-inline-flexbox;
    display:inline-flex;
    -webkit-align-items:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -webkit-justify-content:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-family:inherit;
    font-size:16px;
    -webkit-text-decoration:none;
    text-decoration:none;
    text-transform:capitalize;
    border:0;min-width:170px;
    padding:14px;
    border-radius:3px;
    -webkit-transition:450ms all;
    transition:450ms all;
    position:relative;
    color:#000;
    background-color:#44d62c;
    z-index:999;
}

.buttonMain:hover,
.buttonMain:focus{
    outline:none;
    background:#73e161;
}

.buttonMain .icon-left{
    margin-right:8px;
}

.buttonMain .icon-right{
    margin-left:8px;
}