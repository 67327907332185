.sectionBlock{
	width:100%;
	padding-right:15px;
	padding-left:15px;
	margin:0 auto;
}

.dialogBox .title{
	width:100%;
	background:#fff;
	border:1px solid #000;
	padding:5px;
}

.dialogBox .titleerror{
	width:100%;
	background:#950606;
	border:1px solid #950606;
	color:#fff;
	padding:5px;
}

.dialogBox p{
	padding:10px;
}

.dialogBox .buttonDiv{
	width:100%; text-align:center;
}

textarea {
	resize: vertical;
	min-height:100px;
}

button:disabled {
	background: #dddddd;
}

button:hover:disabled {
	background: #dddddd;
	cursor: not-allowed;
}

.stickyNote{
	display: flex;
	background-color: #f5f2d2;
	border-left: solid 4px #bda418;
	line-height: 16px;
	color:#18191A;
	/* mc-auto-number-format: '{b}Note: {/b}'; */
	overflow: hidden;
	padding: 12px;
	text-align:left;
}

.stickyNote i{
	color:#bda418;
	margin-right:10px;
}

.stickyNote ul {
	margin-top:10px;
}
.stickyNote ul li {
	margin-bottom:8px;
}

.stickyNote a{
	color:#20b609;
	font-weight:500;
}

.stickyNote a:hover{
	color:#44d62c;
}
.Toastify__toast-container--top-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (min-width:576px){
	.sectionBlock{max-width:100%;}
}

@media (min-width:768px){
	.sectionBlock{max-width:720px;}
}

@media (min-width:992px){
	.sectionBlock{max-width:960px;}
}

@media (min-width:1200px){
	.sectionBlock{max-width:1170px;}
}

.contentsBlock{
	width:100%;
	padding-right:15px;
	padding-left:15px;
	margin:0 auto;
}

@media (min-width:576px){
	.contentsBlock{
		max-width:100%;
	}
	.section-title h2, .section-title h4{
		padding-top:50px;
	}
}

@media (min-width:768px){
	.contentsBlock{
		max-width:720px;
	}
	.section-title h2, .section-title h4{
		padding-top:0;
	}
}

@media (min-width:992px){
	.contentsBlock{
		max-width:960px;
	}
	
}

@media (min-width:1200px){
	.contentsBlock{
		max-width:1170px;
	}
}

/* sc-component-id: Row__RowWrapper-sc-4xo3sb-0 */
.bMEOtR{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-left:-15px;
	margin-right:-15px;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
}

.privacyContents, .termsContents{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-left:-15px;
	margin-right:-15px;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	padding:50px 0 0 0;
}

.announcefixedTemp {
    /* display: grid; */
    /* grid-template-columns: repeat(1, 1fr); */
    margin-bottom: 10px;
	width: 100%;
}

.announcefixedTemp .card-single {
    color: #fff;
	background-color: #000;
    font-weight: normal;
    font-size: .8em;
    text-align: center;
    padding:10px;
    border:1px solid;
	width: 50%;
	margin: auto;
	
}

@media (min-width:576px){
	.privacyContents .termsContents{
		padding:50px 20px 0 20px;
	}
}

@media (min-width:315px){
	.privacyContents .termsContents{
		padding:50px 20px 0 20px;
	}
}

.privacyContents, .termsContents{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-left:-15px;
	margin-right:-15px;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	padding:50px 0 0 0;
}

@media (min-width:576px){
	.privacyContents, .termsContents{
		padding:50px 20px 0 20px;
	}
}

@media (min-width:315px){
	.privacyContents, .termsContents{
		padding:50px 20px 0 20px;
	}
	
}

.lmLScy{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-left:-15px;
	margin-right:-15px;
}

.iXQcgR{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-flex-wrap:wrap;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-left:-15px;
	margin-right:-15px;
	-webkit-box-pack:center;
	-webkit-justify-content:center;
	-ms-flex-pack:center;
	justify-content:center;
}

.goZPij{
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
}

@media (min-width:315px){

}

@media (min-width:576px){
	.goZPij{
		-webkit-flex:0 0 58.333333%;
		-ms-flex:0 0 58.333333%;
		flex:0 0 58.333333%;
		max-width:58.333333%;
	}
}

@media (min-width:768px){
	.goZPij{
		-webkit-flex:0 0 50%;
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50%;
	}
}

@media (min-width:992px){

}

@media (min-width:1200px){

}

.fPkWHF{
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
}

@media (min-width:315px){

}

@media (min-width:576px){
	.fPkWHF{
		-webkit-flex:0 0 41.666667%;
		-ms-flex:0 0 41.666667%;
		flex:0 0 41.666667%;
		max-width:41.666667%;
	}
}

@media (min-width:768px){
	.fPkWHF{
		-webkit-flex:0 0 50%;
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50%;
	}
}

@media (min-width:992px){

}

@media (min-width:1200px){

}

.ffYYIb{
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
}

@media (min-width:315px){
	.ffYYIb{
		-webkit-flex:0 0 100%;
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100%;
	}
}

@media (min-width:576px){
	.ffYYIb{
		-webkit-flex:0 0 50%;
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50%;
	}
}

@media (min-width:768px){

}

@media (min-width:992px){

}

@media (min-width:1200px){

}

.EXbOa{
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
}

@media (min-width:315px){
	.EXbOa{
		-webkit-flex:0 0 100%;
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100%;
	}
}

@media (min-width:576px){
	.EXbOa{
		-webkit-flex:0 0 50%;
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50%;
	}
} 

@media (min-width:768px){
	.EXbOa{
		-webkit-flex:0 0 33.333333%;
		-ms-flex:0 0 33.333333%;
		flex:0 0 33.333333%;
		max-width:33.333333%;
	}
}

@media (min-width:992px){

}

@media (min-width:1200px){

}

.cgaNxH{
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
}

@media (min-width:315px){
	.cgaNxH{
		-webkit-flex:0 0 100%;
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100%;
	}
}

@media (min-width:576px){

}

@media (min-width:768px){

}

@media (min-width:992px){

}

@media (min-width:1200px){

}

.jooMJq{
	position:relative;
	width:100%;
	padding-left:15px;
	padding-right:15px;
}

@media (min-width:315px){
	.jooMJq{
		-webkit-flex:0 0 100%;
		-ms-flex:0 0 100%;
		flex:0 0 100%;
		max-width:100%;
	}
}

@media (min-width:576px){
	.jooMJq{
		-webkit-flex:0 0 50%;
		-ms-flex:0 0 50%;
		flex:0 0 50%;
		max-width:50%;
	}
}

@media (min-width:768px){

}

@media (min-width:992px){
	.jooMJq{
		-webkit-flex:0 0 41.666667%;
		-ms-flex:0 0 41.666667%;
		flex:0 0 41.666667%;
		max-width:41.666667%;
	}
}

@media (min-width:1200px){

}

.EQkxe{
	margin-left:auto;
	margin-right:auto;
	width:40%;
	text-align:center;
	text-align:left;
	width:100%;
}

.EQkxe h4{
	font-size:20px;
	font-weight:500;
	color:#ffffff;
	margin:0 0 16px 0;
}

.EQkxe h2{
	font-size:40px;
	font-weight:300;
	line-height:50px;
	color:#44d62c;
	margin:0 0 75px 0;
}

.EQkxe h2 span{
	font-weight:600;
}

@media only screen and (max-width:912px){
	.EQkxe{
		width:100%;
	}
	
	.EQkxe h2{
		font-size:24px;
		line-height:35px;
	}
	.EQkxe .section-title h2{
		margin-bottom:20px;
	}
}

.cXpCP{
	margin-left:auto;
	margin-right:auto;
	width:40%;
	text-align:center;
	width:65%;
}

.cXpCP h4{
	font-size:20px;
	font-weight:500;
	color:#ffffff;
	margin:0 0 16px 0;
}

.cXpCP h2{
	font-size:40px;
	font-weight:300;
	line-height:50px;
	color:#44d62c;
	margin:0 0 75px 0;
}

.cXpCP h2 span{
	font-weight:600;
}

@media only screen and (max-width:912px){
	.cXpCP{
		width:100%;
	}
	
	.cXpCP h2{
		font-size:24px;
		line-height:35px;
	}
	.cXpCP .section-title h2{
		margin-bottom:20px;
	}
}

/* sc-component-id: accordionstyle__CloseIcon-sc-1lk6s55-3 */
.bQPbNl{
	opacity:0;
}

/* sc-component-id: accordionstyle__AccordionTitleWrapper-sc-1lk6s55-4 */
.izgfnI > div{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	cursor:pointer;
	position:relative;
}

.izgfnI > div[aria-expanded='false'] .accordionstyle__OpenIcon-sc-1lk6s55-2{
	opacity:0;
}

.izgfnI > div[aria-expanded='false'] .accordionstyle__CloseIcon-sc-1lk6s55-3{
	opacity:1;
}

.izgfnI:focus{
	outline:none;
}

.izgfnI *{
	-webkit-box-flex:1;
	-webkit-flex-grow:1;
	-ms-flex-positive:1;
	flex-grow:1;
}

/* sc-component-id: accordionstyle__AccordionBodyWrapper-sc-1lk6s55-6 */
.dxtoFQ{
	-webkit-animation:0.35s bcCCNc ease-in;
	animation:0.35s bcCCNc ease-in;
}

.dxtoFQ.accordion__body--hidden{
	-webkit-animation:0.35s bcCCNc ease-in;
	animation:0.35s bcCCNc ease-in;
}

/* sc-component-id: accordionstyle__IconWrapper-sc-1lk6s55-7 */
.dHRtYU{
	margin-left:30px;
	width:40px;
	position:relative;
}

.dHRtYU .accordionstyle__OpenIcon-sc-1lk6s55-2,.dHRtYU .accordionstyle__CloseIcon-sc-1lk6s55-3{
	position:absolute;
	top:50%;
	right:0;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%);
	-webkit-transition:0.25s ease-in-out;
	transition:0.25s ease-in-out;
	color:#44d62c;
}


.screenshotSection .view-more-button{
	height:100%;
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	-webkit-box-pack:end;
	-webkit-justify-content:flex-end;
	-ms-flex-pack:end;
	justify-content:flex-end;
}

.screenshotSection .slick-dots li button:before{
	color:#44d62c;
	opacity:0.2;
}

.screenshotSection .slick-dots li.slick-active button:before{
	color:#44d62c;
	opacity:1;
	content:'';
}

.screenshotSection .slick-slide{
	position:relative;
	padding-left:5px;
	padding-right:5px;
	-webkit-transition:all 300ms ease-in;
	transition:all 300ms ease-in;
	-webkit-transform:scale(0.9);
	-ms-transform:scale(0.9);
	transform:scale(0.9);
	cursor:pointer;
}

@media only screen and (min-width:769px){
	.screenshotSection .slick-slide{
		opacity:0.3;
	}
}

@media only screen and (min-width:769px){
	.screenshotSection .slick-slide.slick-current.slick-active{
		opacity:1;
		-webkit-transform:scale(1);
		-ms-transform:scale(1);
		transform:scale(1);
	}
}

@media only screen and (min-width:769px){
	.screenshotSection .slick-slide:hover{
		opacity:1;
	}
}

.screenshotSection .slick-arrow{
	border:none;
	position:absolute;
	top:-26%;
	right:20%;
	background:none;
	font-size:50px;
	cursor:pointer;
	opacity:0.7;
}

.screenshotSection .slick-arrow:hover{
	opacity:1;
	color:#44d62c;
}

.screenshotSection .slick-prev-icon{
	right:25%;
}

@media only screen and (max-width:912px){
	.screenshotSection{
		padding:130px 0 200px 0;
	}
	
	.screenshotSection .slick-arrow{
		top:auto;
		bottom:-100px;
		right:42%;
	}
	
	.screenshotSection .slick-prev-icon{
		right:50%;
	}
}

@media only screen and (max-width:568px){
	.screenshotSection .section-title-block{
		text-align:center;
	}
	
	.screenshotSection .section-title-block h4{
		width:100%;
	}
	
	.screenshotSection .section-title-block img{
		display:none;
	}
	
	.screenshotSection .view-more-button{
		display:none;
	}
}

@media only screen and (max-width:480px){
	.screenshotSection .slick-slide img{
		margin:0 auto;
	}
	
	.screenshotSection .slick-arrow{
		right:37%;
	}
	
	.screenshotSection .slick-prev-icon{
		right:50%;
	}
}

/* sc-component-id: pricingSectionstyle__PricingSectionWrapper-sc-137wbqf-0 */
.bPNkch{
	padding:90px 0 108px 0;
	overflow:hidden;
	position:relative;
}

.bPNkch .section__particle{
	position:absolute;
}

.bPNkch .section__particle.one{
	width:190px;
	left:-5%;
	bottom:0px;
}

.bPNkch .section__particle.two{
	width:144px;
	top:27%;
	right:-4%;
}

.bPNkch .pricing-block{
	padding:60px 40px 55px 40px;
	border:1px solid #EEE6FA;
	box-shadow:0 0 40px #44d62c;
	border-radius:5px;
	position:relative;
	text-align:center;
	-webkit-transition:all 0.3s ease-in;
	transition:all 0.3s ease-in;
}

.bPNkch .pricing-block:hover{
	border-color:#44d62c;
}

.bPNkch .pricing-block:hover .thumb-block{
	border-color:#44d62c;
}

.bPNkch .pricing-block:hover .pricing-btn{
	opacity:1;
}

.bPNkch .thumb-block{
	padding-bottom:30px;
	border-bottom:1px solid rgba(112,112,112,0.3);
	-webkit-transition:all 0.3s ease-in;
	transition:all 0.3s ease-in;
}

.bPNkch .price-block{
	text-align:center;
	padding:40px 0 23px 0;
}

.bPNkch .price-block h3{
	font-size:36px;
	font-weight:600;
	margin:0 0 10px 0;
}

.bPNkch .price-block p{
	font-size:18px;
	margin-bottom:0;
}

.bPNkch .price-block ~ p{
	margin-top:0;
	margin-bottom:22px;
	text-align:center;
	color:#ffffff;
}

.bPNkch .details-block{
	text-align:left;
}

.bPNkch .details-block span{
	color:#44d62c;
	font-weight:300;
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
}

.bPNkch .details-block span svg{
	fill:#ffffff;
	margin-right:20px;
}

.bPNkch .details-block .off svg{
	fill:#FB7B81;
}

.bPNkch .details-block span + span{
	margin-top:2px;
}

.bPNkch .pricing-btn{
	font-size:15px;
	color:#fff;
	padding:15px 20px;
	margin:0 auto;
	position:absolute;
	text-align:center;
	bottom:-23px;
	left:0;
	right:0;
	opacity:0;
	-webkit-transition:all 0.3s ease-in;
	transition:all 0.3s ease-in;
}

@media only screen and (max-width:1280px){
	.bPNkch{
		padding:90px 0 210px 0;
	}
	
	.bPNkch .pricing-block{
		padding:40px 15px 35px 15px;
	}
	
	.bPNkch .section__particle.one{
		bottom:0px;
	}
	
	.bPNkch .section__particle.two{
		display:none;
	}
}

@media only screen and (max-width:912px){
	.bPNkch{
		padding:50px 0 35px 0;
	}
	
	.bPNkch .section__particle.one{
		bottom:-122px;
	}
	
	.bPNkch .pricing-block{
		margin-bottom:40px;
		padding:40px 30px 35px 30px;
	}
}

@media only screen and (max-width:480px){
	.bPNkch .pricing-block{
		margin:0 15px 40px 15px;
		padding:40px 15px 35px 15px;
	}
}

/* sc-component-id: getAppSectionstyle__GetAppSectionWrap-sc-81a88o-0 */
.eNENYd{
	padding:100px 0 90px 0;
	/* background:url(./img/bg2.png); */
	background-repeat:no-repeat;
	background-size:contain;
	background-position:center center;
	position:relative;
}

.eNENYd .section__particle{
	position:absolute;
}

.eNENYd .section__particle.one{
	width:70px;
	left:7%;
	top:28px;
}

.eNENYd .section__particle.two{
	width:60px;
	bottom:60px;
	right:45%;
}

.eNENYd .section__particle.three{
	width:114px;
	top:200px;
	right:17%;
}

.eNENYd .section__particle.four{
	width:95px;
	bottom:80px;
	right:25%;
}

.eNENYd .getapp-block h3{
	font-size:40px;
	font-weight:500;
	line-height:50px;
	margin-bottom:40px;
}

.eNENYd .getapp-block p{
	font-weight:300;
	margin:0 auto 60px auto;
}

.eNENYd .btn-block{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-pack:justify;
	-webkit-justify-content:space-between;
	-ms-flex-pack:justify;
	justify-content:space-between;
}

.eNENYd .getapp-btn{
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	height:90px;
	min-width:260px;
	border-radius:5px;
	padding:0 12px;
	border:1px solid rgba(122,132,142,0.5);
	-webkit-transition:450ms all;
	-webkit-transition:450ms all;
	transition:450ms all;
}

.eNENYd .getapp-btn:hover{
	color:#ffffff;
	background:#44d62c;
	border-color:transparent;
}

.eNENYd .getapp-btn:hover span,.eNENYd .getapp-btn:hover p,.eNENYd .getapp-btn:hover svg{
	color:#ffffff;
}

.eNENYd .getapp-btn p{
	margin-bottom:0;
	color:#44d62c;
}

.eNENYd .getapp-btn span{
	display:block;
	font-size:20px;
	font-weight:500;
	color:#ffffff;
}

.eNENYd .getapp-btn svg{
	font-size:35px;
	color:#ffffff;
}

.eNENYd .getapp-btn.ios:hover{
	background:#1c86f3;
}

.eNENYd .getapp-btn.android:hover{
	background:#119e58;
}

.eNENYd .getapp-thumb{
	margin:0 auto;
	text-align:center;
}

.eNENYd .getapp-thumb img{
	margin-top:-80px;
	max-width:70%;
}

@media only screen and (max-width:1280px){
	.eNENYd{
		background-size:cover;
	}
	
	.eNENYd .getapp-btn{
		min-width:auto;
		width:48%;
	}
	
	.eNENYd .getapp-btn p{
		font-size:12px;
	}
	
	.eNENYd .getapp-btn span{
		font-size:17px;
	}
	
	.eNENYd .section__particle.four{
		display:none;
	}
}

@media only screen and (max-width:912px){
	.eNENYd{
		background-size:cover;
	}
	
	.eNENYd .getapp-block h3{
		font-size:30px;
		margin-bottom:8px;
	}
	
	.eNENYd .btn-block{
		display:block;
	}
	
	.eNENYd .getapp-btn{
		min-width:auto;
		width:100%;
	}
	
	.eNENYd .getapp-btn p{
		font-size:16px;
	}
	
	.eNENYd .getapp-btn span{
		font-size:19px;
	}
	
	.eNENYd .getapp-btn + .getapp-btn{
		margin-top:30px;
	}
}

@media only screen and (max-width:760px){
	.eNENYd{
		padding:100px 0 50px 0;
	}
	
	.eNENYd .section__particle.two{
		bottom:auto;
		top:0;
		right:auto;
		left:10%;
	}
	
	.eNENYd .section__particle.one,.eNENYd .section__particle.three{
		display:none;
	}
	
	.eNENYd .getapp-btn{
		-webkit-box-pack:center;
		-webkit-justify-content:center;
		-ms-flex-pack:center;
		justify-content:center;
	}
	
	.eNENYd .getapp-btn p{
		margin:0 26px 0px 26px;
	}
}

@media only screen and (max-width:568px){
	.eNENYd{
		text-align:center;
	}
	
	.eNENYd .getapp-block .btn-block{
		display:-webkit-box;
		display:-webkit-flex;
		display:-ms-flexbox;
		display:flex;
	}
	
	.eNENYd .getapp-block .getapp-btn{
		width:260px;
	}
	
	.eNENYd .getapp-block .getapp-btn span{
		font-size:17px;
	}
	
	.eNENYd .getapp-block .getapp-btn p{
		font-size:13px;
		margin:0 15px 0px 15px;
	}
	
	.eNENYd .getapp-block .getapp-btn + .getapp-btn{
		margin-top:0;
	}
	
	.eNENYd .getapp-thumb{
		display:none;
	}
}

@media only screen and (max-width:480px){
	.eNENYd .getapp-block .btn-block{
		display:block;
	}
	
	.eNENYd .getapp-block .getapp-btn{
		width:100%;
	}
	
	.eNENYd .getapp-block .getapp-btn span{
		font-size:23px;
	}
	
	.eNENYd .getapp-block .getapp-btn p{
		font-size:16px;
		margin:0 15px 0px 15px;
	}
	
	.eNENYd .getapp-block .getapp-btn + .getapp-btn{
		margin-top:30px;
	}
}

/* sc-component-id: sc-global-4039681070 */
::selection{
	background:#333333;color:#ffffff;
}
*,*::before,*::after{
	box-sizing:inherit;
}

*{
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}

*:focus{
	outline:none;
}

html{
	box-sizing:border-box;
	-ms-overflow-style:scrollbar;
}

body,html{
	margin:0px;
}

body{
	font-size:16px;
	line-height:28px;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
	font-family:'Poppins',sans-serif;
	color:#ffffff;
	background-color:#18191A;
	overflow-x:hidden !important;
	font-weight:400;
	margin:0;
	padding:0;
}

h1,h2,h3,h4,h5,h6{
	font-family:'Poppins',sans-serif;
	color:#44d62c;
	margin:0;
}


p{
	margin:0 0 15px 0;
}

a{
	-webkit-text-decoration:none;
	text-decoration:none;
	color:#44d62c;
}

img{
	max-width:100%;
}

section{
	position:relative;
}

/* sc-component-id: aboutstyle__AboutSectionWrapper-l7qyes-0 */
.aboutusSection{
	padding:60px 0 60px 0;
	position:relative;
	overflow:hidden;
}

.genericSection{
	padding:120px 0 100px 0;
	position:relative;
	overflow:hidden;
}

.aboutusSection .section__particle, .genericSection .section__particle{
	position:absolute;
}

.aboutusSection .section__particle.one, .genericSection .section__particle.one {
	right:9%;
	top:19%;
	height:34px;
	width:40px;
	-webkit-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	transform:rotate(-45deg);
}

.aboutusSection .section__particle.two, .genericSection .section__particle.two {
	width:150px;
	top:auto;
	bottom:80px;
	left:-22px;
}

.aboutusSection .about-thumb, .genericSection .about-thumb{
	width:100%;
	-webkit-transform:translateX(-80px);
	-ms-transform:translateX(-80px);
	transform:translateX(-80px);
	display:inline-block;
}

.aboutusSection .about-text.text-one, .genericSection .about-text.text-one{
	font-weight:300;
	margin-bottom:28px;
}

.aboutusSection .about-text.text-two, .genericSection .about-text.text-two {
	color:#44d62c;
	font-weight:400;
	margin-bottom:80px;
}

.aboutusSection .section-title h2, .genericSection .section-title h2{
	margin-bottom:38px;
}

.aboutusSection .install-img, .genericSection .install-img {
	white-space:nowrap;
	width:50%;
}
@media only screen and (max-width:1400px){
	.aboutusSection .about-thumb, .genericSection .about-thumb{
		-webkit-transform:translateX(0px);
		-ms-transform:translateX(0px);
		transform:translateX(0px);
	}
	
	.aboutusSection .section__particle.one, .genericSection .section__particle.one {
		top:9%;
	}
	
	.aboutusSection .section__particle.two, .genericSection .section__particle.two {
		bottom:-92px;
		left:-38px;
	}
}

@media only screen and (max-width:1024px){
	.aboutusSection .section__particle.one, .genericSection .section__particle.one{
		top:0%;
	}
}

@media only screen and (max-width:912px){
	.aboutusSection{
		padding:10px 0 60px 0;
	}
	.genericSection {
		padding:100px 0 60px 0;
	}
	.aboutusSection .section-title h2, .genericSection .section-title h2{
		margin-bottom:20px;
	}
	.aboutusSection .about-text.text-two, .genericSection .about-text.text-two {
		margin-bottom:30px;
	}
}

@media only screen and (max-width:760px){
	.aboutusSection{
		padding:10px 0 60px 0;
	}
	.genericSection{
		padding:120px 0 60px 0;
	}

	.aboutusSection .about-thumb, .genericSection .about-thumb{
		margin-bottom:70px;
	}
}

@media only screen and (max-width:568px){
	.aboutusSection{
		padding-top:0;
	}
	.genericSection{
		padding-top:60px;
	}
	.aboutusSection .section-title,
	.aboutusSection .about-content-wrap,
	.genericSection .section-title,
	.genericSection .about-content-wrap
	{
		text-align:center;
	}
	
	.about-content-wrap .section-title img, .genericSection .section-title img{
		display:none;
	}

	.aboutusSection .install-img, .genericSection .install-img{
		width:100%;
		margin:auto;
		white-space:nowrap;
	}

	.aboutusSection .section-title h4, .genericSection .section-title h4{
		width:100%;
	}
	.imgfiller{
		visibility: hidden;
		clear: both;
		display: none;
	}
	.contentMinusFiller{
		margin-top:70px;
	}

}

@media only screen and (max-width:480px){
	.aboutusSection{
		padding:0 0 60px 0;
	}

	.genericSection{
		padding-top: 60px;
	}

	.imgfiller{
		visibility: hidden;
		clear: both;
		float: left;
		display: none;
	}

	
}

@media screen and (max-width: 600px) {
	#title_message {
	  visibility: hidden;
	  clear: both;
	  float: left;
	  margin: 10px auto 5px 20px;
	  width: 28%;
	  display: none;
	}
  }

.featuresSection{
	padding:160px 0 50px 0;
}

.featuresSection .fetures-icon-block{
	height:80px;
	width:80px;
	display:-webkit-box;
	display:-webkit-flex;
	display:-ms-flexbox;
	display:flex;
	-webkit-align-items:center;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;
	margin-bottom:25px;
	position:relative;
	border:2px solid white;
	border-radius:50%;
	-webkit-transition:all 1s;
	transition:all 1s;
}

.featuresSection .fetures-icon-block i{
	display:block;
	margin:0 auto;
	font-size:40px;
}

.featuresSection .fetures-block .fetures-icon-block:before{
	position:absolute;
	display:inline-block;
	top:-3px;
	right:0px;
	height:70px;
	width:70px;
	content:'';
	border-radius:50%;
	z-index:-1;
	opacity:0;
	-webkit-transition:all 0.3s linear;
	transition:all 0.3s linear;
}

.featuresSection .fetures-block:hover .fetures-icon-block{
	border-color:#73e161;
	color:#73e161;
}

.featuresSection .fetures-block h3{
	font-size:22px;
	margin-bottom:15px;
	font-weight:600;
	line-height:30px;
}

.featuresSection .fetures-block p{
	margin:0 0 50px 0;
	font-weight:300;
}

@media only screen and (max-width:912px){
	.featuresSection{
		padding:110px 0 0px 0;
	}
}

@media only screen and (max-width:760px){
	.featuresSection{padding:110px 0 50px 0;
	}
}

@media only screen and (max-width:568px){
	.featuresSection .fetures-block{
		text-align:center;
	}
	
	.featuresSection .fetures-icon-block{
		margin:0 auto 25px auto;
	}
}

/* sc-component-id: faqSectionstyle__FaqSectionWrapper-sc-15to2pb-0 */
.bVLtWi{
	padding:95px 0 140px 0;
	position:relative;
	overflow:hidden;
}

.bVLtWi .section__particle{
	position:absolute;
}

.bVLtWi .section__particle.one{
	width:50px;
	left:8%;
	top:14%;
}

.bVLtWi .section__particle.two{
	width:180px;
	top:43%;
	right:-55px;
}

.bVLtWi .accordion__item + .accordion__item{
	border-color:transparent;
}

.bVLtWi .reusecore__accordion{
	margin-bottom:80px;
}

.bVLtWi .accordion__item .accordion__header{
	padding:20px;
	background:#242526;
}

.bVLtWi .accordion__item .accordion__header h5{
	font-weight:300;
	font-size:16px;
	color:#ffffff;
}

.bVLtWi .accordion__item .accordion__header > div:focus{
	outline:none;
}

.bVLtWi .accordion__item .accordion__body{
	padding:20px;
}

.bVLtWi .accordion__item .accordion__body p{
	font-size:16px;
	font-weight:300;
	margin:0;
}

.bVLtWi .accordion__item + .accordion__item{
	margin-top:20px;
}
.bVLtWi .faq-thumb{
	-webkit-transform:translateX(80px);
	-ms-transform:translateX(80px);
	transform:translateX(80px);
}

.bVLtWi .section-title h2{
	margin-bottom:42px;
}

.bVLtWi .faq-thumb-block{
	-webkit-order:2;
	-ms-flex-order:2;
	order:2;
}

.bVLtWi .faq-content-block{
	-webkit-order:1;
	-ms-flex-order:1;
	order:1;
}

@media only screen and (max-width:1400px){
	.bVLtWi .faq-thumb{
		-webkit-transform:translateX(0px);
		-ms-transform:translateX(0px);
		transform:translateX(0px);
	}
	
	.bVLtWi .section__particle.one{
		left:2%;
	}
}

@media only screen and (max-width:1024px){
	.bVLtWi .section__particle.one{
		left:3%;
		top:5%;
	}
	
	.bVLtWi .section__particle.two{
		top:auto;
		bottom:0;
	}

	.contentMinusFiller{
		margin-top:70px;
	}
}

@media only screen and (max-width:912px){
	.bVLtWi .reusecore__accordion{
		margin-bottom:50px;
	}
}

@media only screen and (max-width:568px){
	.bVLtWi .section-title{
		text-align:center;
	}
	
	.bVLtWi .faq-thumb-block{
		-webkit-order:1;
		-ms-flex-order:1;
		order:1;
		text-align:center;
		margin-bottom:60px;
	}
	
	.bVLtWi .faq-content-block{
		-webkit-order:2;
		-ms-flex-order:2;order:2;
	}
	
	.bVLtWi .reusecore__accordion{
			margin-bottom:50px;
			text-align:left;
	}
}

@media only screen and (max-width:480px){
	.bVLtWi .accordion__item .accordion__header h5{
		font-size:13px;
		line-height:21px;
	}
}

/* sc-component-id: sc-keyframes-bcCCNc */
@-webkit-keyframes bcCCNc{
	0%{
		opacity:0;
	}
	100%{
		opacity:1;
	}
}

@keyframes bcCCNc{
	0%{
		opacity:0;
	}
	100%{opacity:1;
	}
}